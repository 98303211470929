import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import BackBlock from "./BackBlock";
import {Link} from "react-router-dom";


const transactionCheckPaths = {
    "BTC":`https://www.blockchain.com/explorer/transactions/btc/`,
    "FTN":`https://etherscan.io/tx/`,
    "FTNF":`https://sahara.ftnscan.com/tx/`,
    "ETH":`https://etherscan.io/tx/`,
    "USDT":`https://etherscan.io/tx/`,
    "USDT_T":`https://tronscan.io/#/transaction/`,
    "TRX":`https://tronscan.io/#/transaction/`,
    "LTC":`https://blockchair.com/litecoin/transaction/`,
}


export default function TransactionSuccess({onClose}){
    const {t} = useTranslation()
    const {

        amountTo,
        cryptoTo,
        id,
        payoutTrxHash,
        update_at,
        status,
        type,
    } = useSelector(state => state.main.pendingTransaction)



    return (
        <div className={classNames("fastex-content",{failed:status !== 4})}>
            {/*add class "failed" */}
            <BackBlock routeName={type === 0 ? 'exchange' : 'sell'} to={type === 0 ? '/exchange' : '/sell'} onClick={onClose} showArrow={true}/>

            <div className="info-head">
                <dl className='exchange-description'>
                    <div className='exchange-desc-row'>
                        <dt className='exchange-desc-title'>{t('Order')}</dt>
                        <dd className='exchange-desc-value strong'>#{id}</dd>
                    </div>
                    <div className='exchange-desc-row'>
                        <dt className='exchange-desc-title'>{t('Amount')}</dt>
                        <dd className='exchange-desc-value strong'>{amountTo} {cryptoTo}</dd>
                    </div>
                    <div className='exchange-desc-row'>
                        <dt className='exchange-desc-title'>{t('Date')}</dt>
                        <dd className='exchange-desc-value'>{dayjs(update_at).format('DD.MM.YYYY HH:mm')}</dd>
                    </div>
                    {/*<div className='exchange-desc-row'>*/}
                    {/*    <dt className='exchange-desc-title'>{t('Country')}</dt>*/}
                    {/*    <dd className='exchange-desc-value'>Armenia</dd>*/}
                    {/*</div>*/}
                    {/*<div className='exchange-desc-row'>*/}
                    {/*    <dt className='exchange-desc-title'>{t('Email')}</dt>*/}
                    {/*    <dd className='exchange-desc-value'>fulgurik@gmail.com</dd>*/}
                    {/*</div>*/}
                </dl>
                <div className="info-head">
                    <p className="info-data-text">{t(status === 4 ? 'Transaction confirmed' : status === 5 ? 'Failed to deposit' : 'Payout failure')}</p>
                </div>
            </div>
            <div className="info-content">
                <div className="info-content-inner">
                    <div className="info-content-inner-img"/>
                </div>
                <div className="action-buttons">
                    <Link target='_blank' rel="noreferrer" to={transactionCheckPaths[cryptoTo]+payoutTrxHash} className='action-button outline active'>{t('Open Transaction')}</Link>
                </div>
            </div>
            <div className="info-bottom">
                <p className="info-text">
                    <i className="icon-info"/> {t('Transaction is confirmed.')}
                </p>

            </div>
        </div>
    )
}
