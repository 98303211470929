import Email from "../assets/image/email.png";
import Skype from "../assets/image/skype.png";
import {Link} from "react-router-dom";

export default function SupportIcons(){
    return (
        <div className="social-row">
          <Link to='skype:live:.cid.1e311a8a42e5db2d?chat' target='_blank' rel='noreferrer' className='social-img-link icon-skype' />
          <Link to='mailto:support@fulgurpay.com' target='_blank' rel='noreferrer' className='social-img-link icon-message' />
        </div>
    )
}
