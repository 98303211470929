import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store"
import { Provider } from "react-redux";
import {setRate} from "./redux/actions";
import { init } from "./i18n";



function render() {

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <Provider store={store}>
            <App/>
        </Provider>
    );

}
init().then(()=>store.dispatch(setRate()))
.then(render)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
