import numeral from 'numeral'

export default function NumberInput({ value, onChange, className }) {
  function handleNumberChange(event) {
    let value = numeral(event.target.value).value()
    if (value !== null && isNaN(value)) {
      return
    }
    if (event.target.value === '') {
      return onChange('')
    }

    if (value !== null)
      if (
        event.target.value &&
        numeral(event.target.value).format('0.[00000000]') ===
          parseFloat(event.target.value).toString()
      )
        onChange(event.target.value)
  }
  return (
    <input
      type="string"
      className={className}
      value={value}
      onChange={handleNumberChange}
    />
  )
}
