import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {getPendingTransactionInfo} from "../redux/actions";
import TransactionPending from "./TransactionPending";
import TransactionSuccess from "./TransactionSuccess";
import TransactionNotStarted from "./TransactionNotStarted";
import {MAKE_EXCHANGE_FINISH, MAKE_EXCHANGE_SUCCESS} from "../redux/actionTypes";

export default function ExchangeInfo(){
    const dispatch = useDispatch()
    const pend = useSelector(state => state.main.pendingTransaction)

    const {status = 1} = pend
    const intervalRef = useRef()
    useEffect(()=>{

        if(process.env.NODE_ENV === 'production') {
            intervalRef.current = setInterval(() => {
                dispatch(getPendingTransactionInfo())
            }, 10 * 1000)

            return () => {
                clearInterval(intervalRef.current)
            }
        }else{
            setTimeout(()=>{
                dispatch({
                    type: MAKE_EXCHANGE_SUCCESS,
                    payload: {
                        status:2,
                        payoutTrxHash:"asdkjasdkasjndkasjndkasjndk",
                        cryptoTo:"FTN",
                        amountTo:10
                    }
                })
            },15000)
            setTimeout(()=>{
                dispatch({
                    type: MAKE_EXCHANGE_SUCCESS,
                    payload: {
                        status:4
                    }
                })
            },35000)
        }
    },[dispatch])

    useEffect(()=>{
        if(status === 4)
            clearInterval(intervalRef.current)
    },[status])

    function handleClose(){
        dispatch({
            type:MAKE_EXCHANGE_FINISH
        })
    }
    switch (status){
        case 2:
        case 3:
            return <TransactionPending/>
        case 4:
        case 5:
        case 6:
            return <TransactionSuccess onClose={handleClose}/>
        default:
            return <TransactionNotStarted onClose={handleClose}/>

    }
}
