import CurrencySymbol from "./CurrencySymbol";
import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openCurrencyChangePopup} from "../redux/actions";
import {selectCurrenciesToShow} from "../redux/selectors/main.selectors";
import {CHANGE_FROM_CURRENCY, CHANGE_TO_CURRENCY} from "../redux/actionTypes";
import DropDownLight from "./DropDownLight";

export default function CurrencySelect({currencyName, switchTo, excludeFTN, onClick}){

    const dispatch = useDispatch()


    const currencies = useSelector(selectCurrenciesToShow)

    const currencyOptions = useMemo(()=>{
        let list = currencies.map(c=>({
            value:c.name,
            label:c.label,
            icon:<CurrencySymbol currencyName={c.name}/>
        }))
        if(switchTo === 'toCurrency'){
            list = list.filter(l=>l.value !== 'FTN')
        }
        if(excludeFTN){
            return list.filter(c=>!c.value.startsWith('FTN'))
        }

        return list
    },[currencies, excludeFTN,switchTo])

    function selectCurrency(currencyName){
        dispatch({
            type:switchTo==='toCurrency' ? CHANGE_TO_CURRENCY : CHANGE_FROM_CURRENCY,
            payload:currencyName
        })
    }
    return  <div className="crypto-dropdown">
            <DropDownLight list={currencyOptions} selected={currencyName} onChange={selectCurrency}/>
        </div>

}