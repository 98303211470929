import Logo from "../assets/image/new-logo.png";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFromAmount,
    changeToAddress,
    changeToAmount,
    makeExchange,
    switchCurrencies
} from "../redux/actions";
import CurrencySelect from "./CurrencySelect";
import {useState} from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import BackBlock from "./BackBlock";
import DropDownLight from "./DropDownLight";
import {NavLink} from "react-router-dom";

const countries = [
    {
        label:'United States',
        value:'USA'
    },
    {
        label:'Turkey',
        value:'TR'
    },
    {
        label:'Russia',
        value:'RU'
    },
    {
        label:'India',
        value:'IN'
    }
]

export default function MainPage() {

    const {t} = useTranslation()

    const fromAmount = useSelector(state => state.main.fromAmount)
    const fromCurrency = useSelector(state => state.main.fromCurrency)
    const toAmount = useSelector(state => state.main.toAmount)
    const toCurrency = useSelector(state => state.main.toCurrency)
    const networkMap = useSelector(state => state.main.networkMap)
    const addressToPay = useSelector(state => state.main.addressToPay)
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const feeMap = useSelector(state => state.main.feeMap)
    const [country, setCountry] = useState(countries[0].value)
    function handleAddressChange(e) {
        dispatch(changeToAddress(e.target.value))
    }

    function handleToAmountChange(e) {
        dispatch(changeToAmount(e.target.value))
    }

    function handleFromAddressChange(e) {
        dispatch(changeFromAmount(e.target.value))
    }

    function onSwitchCurrencyClick() {
        dispatch(switchCurrencies())
    }

    function handleExchangeClick() {
        dispatch(makeExchange()).catch(e => {
            setError(e.message)
            setTimeout(() => {
                setError('')
            }, 1000)
        })
    }

    return (
        <div className="fastex-content">
            <BackBlock routeName='exchange' to='/'/>
            <div className="pay-receive-card-block">
                <div className="pay-receive-card-row">
                    <span className="from-text">{t('amount-to-pay-value', {value: networkMap[fromCurrency]})}</span>
                    <div className="from-block-items">
                        <input type="text" className="from-inp" onChange={handleFromAddressChange} value={fromAmount}/>
                        <CurrencySelect switchTo="fromCurrency" excludeFTN={toCurrency === 'FTN' || toCurrency === 'FTNF'} currencyName={fromCurrency}/>
                    </div>
                </div>
                <div className="pay-receive-card" onClick={onSwitchCurrencyClick}>
                    <i className="icon-exchange"/>
                </div>
                <div className="to-block">
                    <span className="from-text">{t('amount to receive')} ({networkMap[toCurrency]})</span>
                    <div className="from-block-items">
                        <input type="text" className="from-inp" onChange={handleToAmountChange} value={toAmount}/>
                        <CurrencySelect switchTo="toCurrency"  excludeFTN={fromCurrency === 'FTN' || fromCurrency === 'FTNF'} currencyName={toCurrency}/>
                    </div>
                </div>
            </div>
            <p
              className="estimate-text">{t('estemated-fee-amount', {feeAmount: `${feeMap[fromCurrency + toCurrency]} ${fromCurrency}`})}</p>

            {/*<DropDownLight onChange={setCountry} list={countries} selected={country} label={'Countries'}/>*/}

            <div className="address-block">
                <span
                    className="from-text space-left">{t('enter-your-crypto-address', {currencyName: toCurrency + ' (' + networkMap[toCurrency] + ')'})}
                    <NavLink target='_blank' to={'https://www.bahamut.io/how-to-add-bahamut-mainnet-network-to-metamask'}>(?)</NavLink>
                </span>
                <div className={classNames("from-block-items", {error})}>
                    <input type="text" className={classNames("address-input")} onChange={handleAddressChange}
                           value={addressToPay} placeholder="0xasd123"/>
                    {/*<div className="crypto-block-address-inp">*/}
                    {/*    <div className="crypto-address-block">*/}
                    {/*        <span className="pay-text">{toCurrency}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {error ? <p className="input-error-text">{t(error)}</p> : null}
            </div>
            <div className="action-buttons">
                <NavLink to='/' type='button' className='action-button outline'>cancel</NavLink>
                <button
                  className="action-button filled"
                  type="button"
                  onClick={handleExchangeClick}
                >
                    {t('exchange')}
                </button>
            </div>
        </div>

    )
}
