import classNames from "classnames";
import {useFormikContext} from "formik";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export default function PaymentFields({payments, selecedPaymentId, onChange, payWay}){
    const {setFieldValue, values, errors, touched} = useFormikContext()
    const {t} = useTranslation()
    useEffect(()=>{
        setFieldValue('paymentId', selecedPaymentId);
    },[selecedPaymentId])
    return (
        <div className="address-block">
            <p className='address-block-label'>{t('payment methods')}</p>
            <div className="pay-receive-card-row">
                <div className='addresses-list'>
                    {payments.map(p => (
                        <div
                            className={classNames("payment-method", {active: values.paymentId === p.paymentId})}
                            key={p.paymentId} onClick={() => {
                            setFieldValue('paymentId', p.paymentId);
                            onChange(p.paymentId)
                        }}>
                            <img src={p.logo} alt=''/>
                            <h5 className='payment-method-name'>{p.name}</h5>
                            <p className='payment-method-range'>{p[payWay].minMax.min} {p[payWay].minMax.currency} - {p[payWay].minMax.max} {p[payWay].minMax.currency}</p>
                            <p className='payment-method-fee'>{t('Fee')} {p[payWay].fee}%</p>
                        </div>
                    ))}
                    {!payments.length ? 'no payments available' : null}
                </div>
                {errors.paymentId && touched.paymentId ?
                    <p className="input-error-text">{t(errors.paymentId)}</p> : null}
              {/*<div className='address-list-navigation'>*/}
              {/*  <button type='button' className='icon-down' />*/}
              {/*  <button type='button' className='icon-down' />*/}
              {/*</div>*/}
            </div>

        </div>
    )
}
