import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import QRCode from "qrcode";
import classNames from "classnames";
import Countdown from 'react-countdown';

import SupportIcons from "./SupportIcons";
import {useTranslation} from "react-i18next";
import Logo from "../assets/image/new-logo.png";
import {NavLink} from "react-router-dom";
import BackBlock from "./BackBlock";

export default function TransactionNotStarted({onClose}){
    const [image, setImage] = useState('')
    const {address,expire,id, fromCurrency, type, cryptoFrom} = useSelector(state => state.main.pendingTransaction)
    const networkMap = useSelector(state => state.main.networkMap)

    const {t} = useTranslation()

    const fromAmount = useSelector(state => state.main.fromAmount)
    useEffect(()=>{
        QRCode.toDataURL(address)
            .then((url)=>{
                setImage(url)
            })
    },[address, fromAmount])

    const [copied, setCopied] = useState(false)
    function copyAddress(){
        navigator.clipboard.writeText(address);
        setCopied(true)
        setTimeout(()=>{
            setCopied(false)
        },1000)
    }

    return (
        <div className="fastex-content">
            <BackBlock routeName={type === 0 ? 'exchange' : 'sell'} onClick={onClose} to={type === 0 ? '/' : '/'}/>
            <dl className='exchange-description'>
                <div className='exchange-desc-row'>
                    <dt className='exchange-desc-title'>{t('Order')}</dt>
                    <dd className='exchange-desc-value strong'>#{id}</dd>
                </div>
                <div className='exchange-desc-row'>
                    <dt className='exchange-desc-title'>{t('Amount')}</dt>
                    <dd className='exchange-desc-value strong'>{fromAmount} {fromCurrency}</dd>
                </div>
                {/*<div className='exchange-desc-row'>*/}
                {/*    <dt className='exchange-desc-title'>{t('Payment method')}</dt>*/}
                {/*    <dd className='exchange-desc-value'>Skrill</dd>*/}
                {/*</div>*/}
                {/*<div className='exchange-desc-row'>*/}
                {/*    <dt className='exchange-desc-title'>{t('Country')}</dt>*/}
                {/*    <dd className='exchange-desc-value'>Armenia</dd>*/}
                {/*</div>*/}
                {/*<div className='exchange-desc-row'>*/}
                {/*    <dt className='exchange-desc-title'>{t('Email')}</dt>*/}
                {/*    <dd className='exchange-desc-value'>fulgurik@gmail.com</dd>*/}
                {/*</div>*/}
            </dl>
            <div className='exchange-main-block'>
                <div className='exchange-qr-block'>
                    <img src={image} alt='qr' />
                </div>
                <div className='exchange-main-content'>
                    <div className='exchange-main-awaiting'>
                        {t('awaiting payment')}: <span><Countdown
                      date={Date.now()+expire*1000}
                      renderer={ExpireTime}
                    /></span>
                    </div>
                    <p className='exchange-main-paragraph'>{t('please-deposit')}</p>
                    <button type='button' className={classNames('exchange-copy-btn',{copied})} onClick={copyAddress}>
                        <i />
                        <span className="exchange-copy-text">{t(!copied ? 'copy address' : 'address copied')}</span>
                    </button>
                </div>
            </div>
            <div className="action-buttons">
                <button type='button' className='action-button outline' onClick={onClose}>{t('Cancel')}</button>
            </div>
            <div className="info-bottom">
                <p className="info-text"><i className="icon-info"/> {t('inportant info')}</p>
                <p className="info-text small">
                    {t('send-only-value',{value:`${fromCurrency} (${networkMap[fromCurrency]})`})}
                </p>

            </div>
        </div>
    )
}

function ExpireTime({hours, minutes, seconds, completed}){
    return (
        <>{hours.toString().padStart(2,'0')}:{minutes.toString().padStart(2,'0')}:{seconds.toString().padStart(2,'0')}</>
    )
}
