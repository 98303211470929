import {useSelector} from "react-redux";
import ExchangeInfo from "./ExchangeInfo";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import SupportIcons from "./SupportIcons";
import {useEffect, useState} from "react";

let darkMode = localStorage.getItem('dark-mode-n') === '1'
if(localStorage.getItem('dark-mode-n') === null){
  darkMode = false;
}
export default function Layout() {
  const pendingTransaction = useSelector(state => state.main.pendingTransaction)
  const location = useLocation();
  const [darkModeEnabled, setDarkModeEnabled] = useState(darkMode)

  useEffect(()=>{

    if(darkModeEnabled) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
    localStorage.setItem('dark-mode-n', darkModeEnabled ? '1' : '0')
  },[darkModeEnabled])

  return (
    <div className="container">
      <div className={`app-container ${location.pathname === '/' ? 'home' : ''}`}>
        <div className='fastex-main-header'>
          <NavLink to='/' className='fastex-logo-bg'/>
          <label className='fastex-mode-switcher'>
            <input
              type="checkbox"
              checked={darkModeEnabled}
              onChange={(e) => setDarkModeEnabled(e.target.checked)} />
            <span />
          </label>
        </div>
        {pendingTransaction && pendingTransaction.type !== undefined ? <ExchangeInfo/> : <Outlet/>}
      <SupportIcons/>
      </div>

    </div>
  )
}
