import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

i18n.use(HttpApi).use(initReactI18next)

let loadPath = `static/translations/{{lng}}.json`;
let search = new URLSearchParams(window.location.search)

export function init() {
  let lng = search.get('lang') || 'en';
  return i18n.init({
    lng,
    fallbackLng: 'en',
    debug: true,
    backend: {
      loadPath,
      queryStringParams: { v: '1.3.5' },
    },

  })
}

export function changeLanguage(ln) {
  return i18n.changeLanguage(ln).then(()=>{
    console.log("LNG changed")
  })
}