import {
    CHANGE_FROM_AMOUNT,
    CHANGE_FROM_CURRENCY,
    CHANGE_TO_ADDRESS,
    CHANGE_TO_AMOUNT,
    CHANGE_TO_CURRENCY, MAKE_EXCHANGE_FINISH, MAKE_EXCHANGE_SUCCESS, OPEN_CURRENCY_CHANGE_POPUP, SET_RATES,
    SWITCH_FROM_TO_CURRENCIES
} from "../actionTypes";
import BigNumber from 'bignumber.js'
const BN = BigNumber.clone({ DECIMAL_PLACES: 6 })
const params = new URLSearchParams(window.location.search)

let defaultFtnVal = params.get('ftn') || params.get('amount') || "10"
let addressToPay = params.get('address') || ""
const INITIAL_STATE = {
    currencies: [
        {
            name: "FTNF",
            label: 'FCRC-20 (bahamut)'
        },
        {
            name: "FTN",
            label: 'FTN (erc20)'
        },

        {
            name: "USDT",
            label: 'USDT (erc20)'
        },
        {
            name: "USDT_T",
            label: 'USDT (trc20)'
        },
        {
            name: "TRX",
            label: 'Tron'
        },
        {
            name: "ETH",
            label: 'Etherium'
        },


    ],
    ratesMap: {},
    networkMap:{},
    fromCurrency: "USDT_T",
    toCurrency: "FTNF",
    addressToPay,
    fromAmount: "",
    toAmount: defaultFtnVal,
    pendingTransaction: null,
    changeCurrency: ''
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SET_RATES:
            return {
                ...state,
                ratesMap: action.payload.rateMap,
                networkMap: action.payload.networkMap,
                feeMap: action.payload.feeMap,
                fromAmount: state.toAmount ? new BN(state.toAmount).times(new BN(action.payload.rateMap[state.toCurrency + '_' + state.fromCurrency])).toFixed() : ''
            };
        case OPEN_CURRENCY_CHANGE_POPUP:
            return {
                ...state,
                changeCurrency: action.payload
            };
        case CHANGE_FROM_CURRENCY:


            let state2 = {
                ...state,
                fromCurrency: action.payload,
            }
            console.log(action.payload)
            if(!action.payload.startsWith('FTN')){
                state2.toCurrency = 'FTNF'
                state2.toAmount = state.fromAmount ? new BN(state.fromAmount).times(new BN(state.ratesMap[action.payload + "_FTNF"])).toFixed() : ''
            }

            return state2
        case CHANGE_TO_CURRENCY:
            if(state.toCurrency === action.payload)
                return state
            let state1 = {
                ...state,
                toCurrency: action.payload,

            };

            if(!action.payload.startsWith('FTN')){
                state1.fromCurrency = 'FTNF'
                state1.toAmount = state.fromAmount ? new BN(state.fromAmount).times(new BN(state.ratesMap['FTNF_' + action.payload])).toFixed() : ''
            }

            return  state1
        case CHANGE_TO_ADDRESS:
            return {
                ...state,
                addressToPay: action.payload
            };
        case CHANGE_TO_AMOUNT:
            return {
                ...state,
                toAmount: action.payload,
                fromAmount: action.payload ? new BN(action.payload).div(new BN(state.ratesMap[state.fromCurrency + '_' + state.toCurrency])).toFixed() : ''
            };
        case CHANGE_FROM_AMOUNT:
            return {
                ...state,
                fromAmount: action.payload,
                toAmount: action.payload ? new BN(action.payload).times(new BN(state.ratesMap[state.fromCurrency + '_' + state.toCurrency])).toFixed() : ''
            };
        case MAKE_EXCHANGE_SUCCESS:
            return {
                ...state,
                pendingTransaction: {
                    ...state.pendingTransaction,
                    ...action.payload,
                    status: parseInt(action.payload?.status) || 1
                }
            };


        case MAKE_EXCHANGE_FINISH:
            return {
                ...state,
                pendingTransaction: null
            };
        case SWITCH_FROM_TO_CURRENCIES:

            return {
                ...state,
                fromCurrency: state.toCurrency,
                toCurrency: state.fromCurrency,
                fromAmount: state.toAmount,
                toAmount: state.fromAmount
            };

        default:
            return state;
    }
};

export default reducer;
