import {Navigate, NavLink, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPendingTransactionInfo} from "../redux/actions";
import {MAKE_EXCHANGE_SUCCESS} from "../redux/actionTypes";

export default function BuyResult(){
    const [params] = useSearchParams()
    const randToken = params.get('randToken')
    const orderId = params.get('orderId')
    const tr = useSelector(state => state.main.pendingTransaction)
    const [loaded, setLoaded] = useState(false)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch({
            type:MAKE_EXCHANGE_SUCCESS,
            payload:{
                randToken,
                id:orderId
            }
        })
        dispatch(getPendingTransactionInfo())
            .then(()=>{
                setLoaded(true)
            })
    },[randToken, orderId])
    if(!randToken || !orderId){
        console.log(123)
        return <Navigate to={'/buy'}/>
    }
    else if(loaded && !tr){
        console.log(456)
        return <Navigate to={'/buy'}/>
    }
    else if(!tr || tr.type === undefined){
        return null
    }
    else{
        return <Navigate to={'/buy'}/>
    }
}