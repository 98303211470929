import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import LoaderImg from "../assets/image/loader-img.png";
import {Link, NavLink} from "react-router-dom";
import BackBlock from "./BackBlock";

const transactionCheckPaths = {
    "BTC":`https://www.blockchain.com/explorer/transactions/btc/`,
    "FTN":`https://etherscan.io/tx/`,
    "ETH":`https://etherscan.io/tx/`,
    "USDT":`https://etherscan.io/tx/`,
    "USDT_T":`https://tronscan.io/#/transaction/`,
    "TRX":`https://tronscan.io/#/transaction/`,
    "LTC":`https://blockchair.com/litecoin/transaction/`,
}

export default function TransactionPending(){

    const {t} = useTranslation()
    const {

        amountTo,
        cryptoTo,
        id,
        payoutTrxHash,
        update_at,
        type,
        cryptoFrom
    } = useSelector(state => state.main.pendingTransaction)




    return (
        <div className="fastex-content ">
            <BackBlock routeName={type === 0 ? 'exchange' : 'sell'} to='/exchange' showArrow={false}/>
            <dl className='exchange-description'>
                <div className='exchange-desc-row'>
                    <dt className='exchange-desc-title'>{t('Order')}</dt>
                    <dd className='exchange-desc-value strong'>#{id}</dd>
                </div>
                <div className='exchange-desc-row'>
                    <dt className='exchange-desc-title'>{t('Amount')}</dt>
                    <dd className='exchange-desc-value strong'>{amountTo} {cryptoFrom}</dd>
                </div>
                {/*<div className='exchange-desc-row'>*/}
                {/*    <dt className='exchange-desc-title'>{t('Payment method')}</dt>*/}
                {/*    <dd className='exchange-desc-value'>Skrill</dd>*/}
                {/*</div>*/}
                {/*<div className='exchange-desc-row'>*/}
                {/*    <dt className='exchange-desc-title'>{t('Country')}</dt>*/}
                {/*    <dd className='exchange-desc-value'>Armenia</dd>*/}
                {/*</div>*/}
                {/*<div className='exchange-desc-row'>*/}
                {/*    <dt className='exchange-desc-title'>{t('Email')}</dt>*/}
                {/*    <dd className='exchange-desc-value'>fulgurik@gmail.com</dd>*/}
                {/*</div>*/}
            </dl>
            <div className="info-head">
                <p className="info-data-text">{t('Transaction is processing')}</p>
            </div>
            <div className="info-content">
                <div className="info-content-inner">
                    <div className="loader">
                        <img src={LoaderImg} alt="" className="loader-img"/>
                        <div className="loader-inner"/>
                    </div>
                </div>
                <div className="action-buttons">
                    <Link target='_blank' rel="noreferrer" to={transactionCheckPaths[cryptoTo]+payoutTrxHash} className='action-button outline active'>{t('Open Transaction')}</Link>
                </div>
            </div>
            <div className="info-bottom">
                <p className="info-text">
                    <i className="icon-info"/> {t('You transaction is processing please wait.')}
                </p>

            </div>
        </div>
    )
}
