import {NavLink} from "react-router-dom";
import SupportIcons from "./SupportIcons";
import {useTranslation} from "react-i18next";
import FulgurMinLogo from './../assets/image/fulgur-logo-min.png';
import TutumMinLogo from './../assets/image/tutum-logo-min.png';

const params = new URLSearchParams(window.location.search)

export default function Home() {

  const {t, i18n} = useTranslation()

  function openFastExchange() {
    window.location.assign('https://fulgurwallet.com/?lang=' + i18n.language + '&refId=' + params.get('refId'))
  }

  function openTutumWayExchange() {
    window.location.assign('https://tutumway.com')
  }

  return (
    <div className='fastex-home-page' >
      <div className='fastex-home-nav-block'>
        <NavLink className='fastex-home-nav-el ftn-box' to='/buy'>
          <span className='fastex-home-nav-title'>{t('Buy')} FTN</span>
          {/*<span className='fastex-home-nav-paragraph'>lorem ipsum</span>*/}
        </NavLink>
        <NavLink className='fastex-home-nav-el sell-box' to='/sell'>
          <span className='fastex-home-nav-title'>{t('Sell')} FTN</span>
          {/*<span className='fastex-home-nav-paragraph'>lorem ipsum</span>*/}
        </NavLink>
        <NavLink className='fastex-home-nav-el exchange-box' to='/exchange'>
          <span className='fastex-home-nav-title'>{t('exchange')} FTN</span>
          {/*<span className='fastex-home-nav-paragraph'>lorem ipsum</span>*/}
        </NavLink>
      </div>
      <div className='fastex-home-button-group'>
        <button
          className='fastex-home-button'
          type='button'
          onClick={openFastExchange}
        >
          {t('Fulgur Wallet')}
          <img src={FulgurMinLogo} alt='' />
        </button>
        <button
          className='fastex-home-button'
          type='button'
          onClick={openTutumWayExchange}
        >
          {t('TutumWay exchange')}
          <img src={TutumMinLogo} alt='' />
        </button>
      </div>
      {/*<SupportIcons/>*/}
    </div>
  )
}
