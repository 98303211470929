import {NavLink} from "react-router-dom";

const BackBlock = ({routeName, to, onClick, showArrow =true, children}) => {
  return (
    <div className="back-block">
      <NavLink to={to} onClick={onClick || undefined}>{showArrow ? <i className={'icon-back-button'}/> : null}{routeName}</NavLink>
        {children}
    </div>
  );
};

export default BackBlock;
