import {
  CHANGE_FROM_AMOUNT,

  CHANGE_GAME_STATE,
  CHANGE_TO_ADDRESS,
  CHANGE_TO_AMOUNT, MAKE_EXCHANGE_FINISH,
  MAKE_EXCHANGE_SUCCESS,
  OPEN_CURRENCY_CHANGE_POPUP,
  SET_RATES,
  SWITCH_FROM_TO_CURRENCIES,
} from "./actionTypes";
import {getRate, getTransactionStatus, makeExchangeCall} from "../api";
import pick from "lodash.pick"

export const changeToAddress = (payload) => {
  return {
    type: CHANGE_TO_ADDRESS,
    payload,
  };
};
export const changeToAmount = (payload) => {
  return {
    type: CHANGE_TO_AMOUNT,
    payload,
  };
};
export const changeFromAmount = (payload) => {
  return {
    type: CHANGE_FROM_AMOUNT,
    payload,
  };
};
export const switchCurrencies = () => {
  return {
    type: SWITCH_FROM_TO_CURRENCIES
  };
};
export const openCurrencyChangePopup = (payload) => {
  return {
    type: OPEN_CURRENCY_CHANGE_POPUP,
    payload
  };
};

export const setRate = () => {
  return (dispatch, getState)=>{

    const {main:{fromCurrency, toCurrency}} = getState()
    return getRate(fromCurrency, toCurrency).then(({data})=>{

      let rateMap = {}
      let feeMap = {}
      let networkMap = {}
      for(let rate of data.data){
        rateMap[rate.cryptoFrom+'_'+rate.cryptoTo] = rate.rate
        feeMap[rate.cryptoFrom + rate.cryptoTo] = rate.estimateFee;
        networkMap[rate.cryptoTo] = rate.network;
      }


      dispatch({
        type: SET_RATES,
        payload: {
          rateMap,
          feeMap,
          networkMap
        }
      });
    })

  }

};

export const makeExchange = () => {
  return async (dispatch, getState)=>{
    const state = getState()

    let data = await makeExchangeCall(pick(state.main,['fromCurrency', 'toCurrency', 'addressToPay']))

    console.log(data)
    dispatch({
      type: MAKE_EXCHANGE_SUCCESS,
      payload: data
    })
  }
};
export const getPendingTransactionInfo = () => {
  return async (dispatch, getState)=>{
    const state = getState()


    try {

      let {data} = await getTransactionStatus(state.main.pendingTransaction)
      if(!data.code){
        dispatch({
          type: MAKE_EXCHANGE_SUCCESS,
          payload: data.data
        })
      }
      else {
        dispatch({
          type: MAKE_EXCHANGE_FINISH
        })
        console.log(7777)
      }
    }catch (e){
      console.error(e)
    }
  }
};

export const changeState = (payload) => ({ type: CHANGE_GAME_STATE, payload });
