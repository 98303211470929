import React, { useMemo, useState } from 'react'
import useOutsideClick from '../hooks/useOnClickOutside'
import classNames from 'classnames'

export default function DropDownLight({ list, label, onChange, theme, selected, size }) {
  const [active, setActive] = useState(false)
  const ref = useOutsideClick(() => {
    setActive(false)
  })
  const selectedItem = useMemo(() => {
    return list.find(
      (l) => l.value === (selected === undefined ? list[0].value : selected)
    )
  }, [list, selected])
  function handleValueSelect(option) {
    onChange(option.value)
    setActive(false)
  }
  // <div className='country-dropdown-wrapper'>
  //   <span className='country-dropdown-label'>Select country</span>
  //   {/*ToDo: Add 'active' to open dropdown*/}
  //   <div className='country-dropdown'>
  //     <p className='country-dropdown-selected'>United States</p>
  //     <ul className='country-dropdown-list'>
  //       <li className='country-dropdown-li'>United State</li>
  //       <li className='country-dropdown-li'>USSR</li>
  //       <li className='country-dropdown-li'>USSR</li>
  //     </ul>
  //   </div>
  // </div>

  return (
    <div
      className={classNames(
        'country-dropdown-wrapper down',
        size ? size : 'big',
        `theme-${theme || '2'}`
      )}
      ref={ref}
    >
      {/*label*/}
      {label ? <span className='country-dropdown-label'>{label}</span> : null}
      <div className={classNames("country-dropdown",{ active })}>
        <p className='country-dropdown-selected' onClick={() => setActive(!active)}>{selectedItem.icon ? selectedItem.icon : null} {selectedItem.label}</p>
        <ul className="country-dropdown-list">
          {list.map((l) => (
              <li
                  key={l.label}
                  className={classNames('country-dropdown-li', {
                    selected: selectedItem.value === l.value,
                  })}
                  onClick={() => handleValueSelect(l)}
                  // add class "selected"
              >{l.icon} {l.label}</li>))}
        </ul>
      </div>
    </div>
  )
}
