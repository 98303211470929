import {NavLink, useNavigate} from "react-router-dom";
import classNames from "classnames";
import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import CurrencySymbol from "./CurrencySymbol";
import {Field, Form, Formik, useFormikContext} from 'formik'
import {object, string, number} from 'yup'
import NumberInput from "./NumberInput";
import {buyFTN, getPayments} from "../api";
import {makeExchange} from "../redux/actions";
import {MAKE_EXCHANGE_SUCCESS} from "../redux/actionTypes";
import BackBlock from "./BackBlock";
import DropDownLight from "./DropDownLight";
import PaymentMethod from './../assets/image/payment-method.png';
import PaymentFields from "./PaymentFields";

let buyScheme = object({
    paymentId: string().required('select payment system').min(1, 'select payment system'),
    address: string()
        .required('enter your address')
        .min(0, 'invalid address')
        .max(42, 'invalid address'),
    amount: number().required('enter amount').min(1, 'at least 5 FTN'),
})

let sellScheme = object({
    paymentId: string().required('select payment system').min(1, 'select payment system'),

    amount: number().required('enter amount').min(1, 'at least 5 FTN'),
})

const validate = (values) => {
    const errors = {};
    for (let key in values) {
        if (values.hasOwnProperty(key) && !values[key])
            errors[key] = 'required'
    }
    if ('email' in values) {
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
    }
    //...

    return errors;
};


export default function BuySell({payWay, initialFields}) {
    const networkMap = useSelector(state => state.main.networkMap)
    const {t} = useTranslation()
    const [error, setError] = useState('')
    const [payments, setPayments] = useState([])
    const [fieldValues, setFieldValues] = useState(null)
    const [selectedPaymentId, setSelectedPaymentId] = useState('')
    const [showAdditionalFields, setShowAdditionalFields] = useState(false)
    const dispatch = useDispatch()
    const selectedPayment = useMemo(() => {
        return payments.find(p => p.paymentId === selectedPaymentId)
    }, [selectedPaymentId, payments])

    const [currency, setCurrency] = useState('')
    const currencyOptions = useMemo(() => {
        let options = [{
            label: 'All',
            value: ''
        }]
        let supC = new Set()
        payments.forEach(p => {
            supC.add(p.sendCurrency)
        })
        for (let c of supC) {
            options.push({
                label: c,
                value: c
            })
        }

        return options

    }, [payments, payWay])

    useEffect(() => {
        setFieldValues(null)
        setShowAdditionalFields(false)

    }, [selectedPaymentId])
    useEffect(() => {
        setSelectedPaymentId('')

    }, [currency])

    const additionalFields = useMemo(() => {
        let obj = {}
        if (!selectedPayment)
            return obj
        for (let i = 0; i < selectedPayment[payWay].fields.length; i++) {
            let f = selectedPayment[payWay].fields[i]
            if (f.name !== 'amount') {
                obj[f.name] = ''
            }
        }

        return obj

    }, [selectedPayment, fieldValues])

    useEffect(() => {
        getPayments()
            .then(({data}) => {
                let paymenys = data.data.filter(p => p[payWay] !== null)
                setPayments(paymenys)
                setSelectedPaymentId(paymenys[0].paymentId)
            })
            .catch(e => {
                console.error(e)
            })
    }, [])
    const navigate = useNavigate()

    function handleExchangeClick() {
        if (showAdditionalFields)
            setShowAdditionalFields(false)
        else
            navigate('/')
    }

    function submitForm(values) {

        setFieldValues(values)

        setShowAdditionalFields(true)

    }

    async function submitForm2(values, form) {
        try {
            if (payWay !== 'deposit')
                return handleSellFTN(values, form)
            let resp = await buyFTN({
                ...fieldValues,
                formData: {
                    ...values,
                    amount: fieldValues.amount
                }
            }, payWay)

            let res = resp.data.data
            if (resp.data.code > 0) {
                setError(resp.data.message)
                setTimeout(()=>{
                    setError('')
                },1000)
                return
            }
            if (res.actionType === 'redirect') {
                let url = res.form.action
                let fields = res.form.fields
                const form = document.createElement('form')
                form.action = url
                form.method = res.form.method
                // form.target = '_blank'
                form.style.display = 'none'
                Object.keys(fields).forEach((f) => {
                    let el = document.createElement('input')
                    el.type = 'hidden'
                    el.value = fields[f.toString()]
                    el.name = f.toString()
                    form.appendChild(el)
                    console.log("A", f, fields, fields[f.toString()])
                })
                let btn = document.createElement('button')
                btn.type = 'submit'
                form.appendChild(btn)

                document.documentElement.appendChild(form)
                setTimeout(() => {
                    form.focus({
                        preventScroll: true
                    })
                    form.submit()

                }, 200)
                //esim xi dreci
                setTimeout(() => {
                    document.documentElement.removeChild(form)
                }, 1000)
            } else if (res.actionType === 'iframe') {
                let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=100,top=100`

                window.open(res.form.action, '_blank', params)
            }
        } catch (e) {
            console.error(e)
        }

    }

    async function handleSellFTN(values, form) {
        try {
            let resp = await buyFTN({
                ...fieldValues,
                formData: {
                    ...values,
                    amount: fieldValues.amount
                }
            }, payWay)
            if (resp.data.code > 0) {
                setError(resp.data.message)
                setTimeout(() => {
                    setError('')
                }, 1000)
                return
            }
            dispatch({
                type: MAKE_EXCHANGE_SUCCESS,
                payload: {
                    ...resp.data.data,
                    amountTo: fieldValues.amount
                }
            })
        }catch (e) {

        }
        // .catch(e => {
        //     // setError(e.message)
        //     // setTimeout(() => {
        //     //     setError('')
        //     // }, 1000)
        // })
    }

    let filteredPayments = currency ? payments.filter(p => p.sendCurrency === currency) : payments
    return (
        <div className="fastex-content">
            <BackBlock showArrow={true} to={'/'} routeName={payWay === 'deposit' ? 'Buy' : 'Sell'}>
                <div>
                    <DropDownLight label={'currency'} selected={currency} list={currencyOptions}
                                   onChange={setCurrency}/>
                </div>
            </BackBlock>

            {!showAdditionalFields ?
                <Formik
                    validationSchema={payWay === "deposit" ? buyScheme : sellScheme}
                    key={currency}

                    initialValues={initialFields} onSubmit={submitForm}>
                    {({errors, touched, values, setFieldValue}) => (
                        <Form className={'address-block'}>
                            <PaymentFields
                                payments={filteredPayments}
                                onChange={pid=>setSelectedPaymentId(pid)}
                                payWay={payWay}
                                selecedPaymentId={selectedPaymentId}/>

                            {initialFields.address !== undefined ? <div className="input-block">
                                <span
                                    className="from-text space-left">{t('enter-your-crypto-address', {currencyName: 'FTN' + ' (' + networkMap['FTNF'] + ')'})}
                                    <NavLink
                                        to='https://www.bahamut.io/how-to-add-bahamut-mainnet-network-to-metamask'
                                        target='_blank'>(?)</NavLink>
                                </span>
                                <div
                                    className={classNames("from-block-items", {error: errors.address && touched.address})}>
                                    <Field type="text" name='address' className={classNames("address-input")}
                                           placeholder="0xasd123"/>

                                </div>
                                {errors.address && touched.address ?
                                    <p className="input-error-text">{t(errors.address)}</p> : null}
                            </div> : null}
                            <div className="input-block">
                                <div className="pay-receive-card-row">
                                    <span className="from-text">{t('amount-to-pay-value', {value: networkMap['FTNF']})}
                                        <NavLink
                                            to='https://www.bahamut.io/how-to-add-bahamut-mainnet-network-to-metamask'
                                            target='_blank'>(?)</NavLink>
                                    </span>
                                    <div className="from-block-items">
                                        <NumberInput value={values.amount} onChange={v => setFieldValue('amount', v)}
                                                     className='address-input'/>
                                        <div className="crypto-block-inp"
                                             style={{minWidth: '80px', position: "absolute", right: '16px', cursor:'default'}}>
                                            <CurrencySymbol currencyName={'FTN'} className="pay-bg"/>
                                            <span className="pay-text" title={'FTN'}>{'FTN'}</span>
                                        </div>
                                        {errors.amount && touched.amount ?
                                            <p className="input-error-text">{t(errors.amount)}</p> : null}
                                    </div>
                                    <div className='amount-variants'>
                                        {[30, 50, 100, 200].map(v => (
                                            <div className='amount-variant' key={v}
                                                 onClick={() => setFieldValue('amount', v)}>{v} FTN</div>))}
                                    </div>


                                </div>

                            </div>

                            <div className="action-buttons">
                                {error ? <p className="input-error-text">{t(error)}</p> : null}

                                <button type='button' className='action-button outline'
                                        onClick={handleExchangeClick}>{t('Cancel')}
                                </button>
                                <button
                                    className="action-button filled"
                                    type="submit"
                                >
                                    {t('Next')}
                                </button>
                            </div>


                        </Form>
                    )}
                </Formik>
                : <div className='address-block'>
                    <dl className='exchange-description'>
                        <div className='exchange-desc-row'>
                            <dt className='exchange-desc-title'>{t('Amount')}</dt>
                            <dd className='exchange-desc-value strong'>{fieldValues.amount} FTN</dd>
                        </div>
                        <div className='exchange-desc-row'>
                            <dt className='exchange-desc-title'>{t('Payment')}</dt>
                            <dd className='exchange-desc-value'>{selectedPayment?.name}</dd>
                        </div>
                        {fieldValues.address ? <div className='exchange-desc-row'>
                            <dt className='exchange-desc-title'>{t('Address')}</dt>
                            <dd className='exchange-desc-value address'>{
                                fieldValues.address.substring(0, 9) +
                                '...' +
                                fieldValues.address.substring(fieldValues.address.length - 4, fieldValues.address.length)
                            }</dd>
                        </div> : null}
                    </dl>
                    <Formik validate={validate} initialValues={additionalFields} onSubmit={submitForm2}>
                        {({errors, touched, values, isValid}) => (
                            <Form className={'address-block'}>
                                <div className='address-block-fields'>
                                    {selectedPayment[payWay].fields.filter(f => f.name !== 'amount').map(field => (
                                        <div className="input-block" key={field.name}>
                                            <div className="pay-receive-card-row">
                                                <span className="from-text">{field.label}</span>
                                                <div className="from-block-items">
                                                    <Field className='address-input' name={field.name}
                                                           type={field.type}/>
                                                </div>
                                                {errors[field.name] && touched[field.name] ?
                                                    <p className="input-error-text">{t(errors[field.name])}</p> : null}
                                            </div>

                                        </div>))}
                                    <Field className='address-input' name={'amount'} type={'hidden'}/>
                                </div>


                                <div className="action-buttons">
                                    {error ? <p className="input-error-text">{t(error)}</p> : null}
                                    <button type='button' className='action-button outline'
                                            onClick={handleExchangeClick}>cancel
                                    </button>
                                    <button
                                        className="action-button filled"
                                        type="submit"
                                    >
                                        {t('exchange')}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>


                </div>}

        </div>
    )
}
