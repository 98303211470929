import './App.scss';

import MainPage from "./compoennts/MainPage";
import ExchangeInfo from "./compoennts/ExchangeInfo";
import {useSelector} from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Layout from "./compoennts/Layout";
import Home from "./compoennts/Home";
import BuySell from "./compoennts/BuySell";
import BuyResult from "./compoennts/BuyResult";
import {useMemo} from "react";

function App() {

    const initialFields = useMemo(()=>{
        let query = new URLSearchParams(window.location.search)
        return {
            address:query.get('address') || '',
            paymentId:'',
            amount:query.get('amount') || undefined,
        }
    },[])

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/exchange' element={<MainPage/>}/>
                    <Route path='/buy' element={<BuySell payWay='deposit' initialFields={initialFields}/>}/>
                    <Route path='/buy-result' element={<BuyResult/>}/>
                    <Route path='/sell' element={<BuySell payWay='withdraw'  initialFields={initialFields}/>}/>
                    <Route path={'*'} element={<Navigate to={'/'}/>}/>
                </Route>
            </Routes>
        </BrowserRouter>

    )
}


export default App;
