
export const CHANGE_FROM_CURRENCY = "CHANGE_FROM_CURRENCY"
export const CHANGE_TO_CURRENCY = "CHANGE_TO_CURRENCY"
export const SWITCH_FROM_TO_CURRENCIES = "SWITCH_FROM_TO_CURRENCIES"

export const CHANGE_TO_ADDRESS = "CHANGE_TO_ADDRESS"
export const CHANGE_TO_AMOUNT = "CHANGE_TO_AMOUNT"
export const CHANGE_FROM_AMOUNT = "CHANGE_FROM_AMOUNT"

export const MAKE_EXCHANGE_SUCCESS = "MAKE_EXCHANGE_SUCCESS"
export const MAKE_EXCHANGE_FINISH = "MAKE_EXCHANGE_FINISH"

export const OPEN_CURRENCY_CHANGE_POPUP = "OPEN_CURRENCY_CHANGE_POPUP"
export const SET_RATES = "SET_RATES"

export const CHANGE_GAME_STATE = "CHANGE_GAME_STATE";
