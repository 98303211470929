import axios from "axios"


const url = process.env.NODE_ENV === 'production' ? '/api/service.php' : 'https://exchange.fulgurpay.com/api/service.php'
const axiosInstance = axios.create({baseURL:url})

const params = new URLSearchParams(window.location.search)
export function makeExchangeCall({fromCurrency, toCurrency, addressToPay}){
    return  axiosInstance.post('',{"request":"changeCrypto","data":{
            "refId":params.get('refId') || 0,
            "refSubId":params.get('refSubId') || 0,
            "cryptoFrom":fromCurrency,
            "cryptoTo":toCurrency,
            "payoutAddress":addressToPay,
            "lang":"en"
        }}).then(({data})=>{
            if(data.code)
                throw new Error(data.message)
        return data.data
    })
}

export function getRate(fromCurrency, toCurrency){
    return  axiosInstance.post('',{
        request:"getRate",
        data:{
            cryptoFrom:fromCurrency,
            cryptoTo:toCurrency,

        }
    })
}

export function getTransactionStatus({id, randToken}){
    return  axiosInstance.post('',{
        request:"getStatus",
        data:{
            getId:id,
            randToken

        }
    })
}
export function getPayments(){
    return  axiosInstance.post('',{
        request:"getFiatPaymentList",
        data:{}
    })
}
export function buyFTN(data, payWay){
    return  axiosInstance.post('',{
        request:payWay === "deposit" ? "buyFTN" : "sellFTN",
        data
    })
}